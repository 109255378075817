var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CSidebar',{attrs:{"aside":"","show":_vm.$store.state.asideShow,"colorScheme":"light","overlaid":"","size":"lg"},on:{"update:show":(val) => _vm.$store.commit('set', ['asideShow', val])}},[_c('CSidebarClose',{nativeOn:{"click":function($event){return _vm.$store.commit('toggle', 'asideShow')}}}),_c('CTabs',{staticClass:"nav-underline nav-underline-primary",attrs:{"tabs":""}},[_c('CTab',{attrs:{"active":""}},[_c('template',{slot:"title"},[_c('font-awesome-icon',{attrs:{"icon":"bell"}}),_c('small',{staticClass:"text-muted"},[(_vm.aside_notification_counter > 0)?_c('CBadge',{staticStyle:{"margin-bottom":"100%"},attrs:{"color":"danger","shape":"pill"}},[_vm._v(_vm._s(_vm.aside_notification_counter))]):_vm._e()],1)],1),_c('CListGroup',{staticClass:"list-group-accent aside-scroll"},_vm._l((_vm.notifications),function(notification,index){return _c('span',{key:index},[(notification.type == 'header')?_c('CListGroupItem',{staticClass:"list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small"},[_c('div',[_vm._v(_vm._s(notification.title))])]):_c('CListGroupItem',{staticClass:"list-group-item-accent-info list-group-item-divider",attrs:{"to":{ path: `/${_vm.$store.getters['getOrganizationName']}/general-access/document-routing`, 
                                query: { 
                                    tab: (notification.status == 'signing' && notification.category == 'Tasks') ? 'signing-list' 
                                            : (notification.status == 'signing' && notification.category == 'Updates') ? 'processing-list' 
                                            : notification.status == 'completed' ? 'completed-list' 
                                            : 'cancelled-list', 
                                    reference_id: notification.reference_id 
                                }
                            }},nativeOn:{"click":function($event){return _vm.clickNotification(notification.reference_id, notification.category, notification.text, index)}}},[_c('div',[_vm._v(_vm._s(notification.title))]),_c('small',{staticClass:"text-muted"},[_c('div',[_vm._v("Reference Number: "+_vm._s(notification.text))])]),_c('small',{staticClass:"text-muted mr-3"},[_c('CIcon',{attrs:{"name":"cil-calendar"}}),_vm._v("  "+_vm._s(notification.date)+" ")],1)])],1)}),0)],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }