<template>
    <CSidebar 
        aside 
        :show="$store.state.asideShow"
        @update:show="(val) => $store.commit('set', ['asideShow', val])"
        colorScheme="light"
        overlaid
        size="lg"
    >
        <CSidebarClose @click.native="$store.commit('toggle', 'asideShow')"/>

        <CTabs tabs class="nav-underline nav-underline-primary">
            <CTab active >
                <template slot="title">
                    <font-awesome-icon icon="bell"/>
                    <small class="text-muted">
                        <CBadge color="danger" shape="pill" style="margin-bottom: 100%;" v-if="aside_notification_counter > 0">{{aside_notification_counter}}</CBadge>
                    </small>
                </template>

                <CListGroup class="list-group-accent aside-scroll" >
                    <span
                        v-for="(notification, index) in notifications"
                        v-bind:key="index"
                        
                    >
                        <CListGroupItem
                        
                            v-if="notification.type == 'header'"
                            class="list-group-item-accent-secondary bg-light text-center font-weight-bold text-muted text-uppercase small"
                        >
                        
                            <div>{{notification.title}}</div>
                        </CListGroupItem>

                        <CListGroupItem
                        
                            v-else
                            @click.native="clickNotification(notification.reference_id, notification.category, notification.text, index)"
                            class="list-group-item-accent-info list-group-item-divider"
                            :to="{ path: `/${$store.getters['getOrganizationName']}/general-access/document-routing`, 
                                    query: { 
                                        tab: (notification.status == 'signing' && notification.category == 'Tasks') ? 'signing-list' 
                                                : (notification.status == 'signing' && notification.category == 'Updates') ? 'processing-list' 
                                                : notification.status == 'completed' ? 'completed-list' 
                                                : 'cancelled-list', 
                                        reference_id: notification.reference_id 
                                    }
                                }"
                        >
                            <div>{{notification.title}}</div>
                            <small class="text-muted">
                                <div>Reference Number: {{notification.text}}</div>
                            </small>
                            
                            <small class="text-muted mr-3">
                                <CIcon name="cil-calendar"/>&nbsp;&nbsp;{{notification.date}}
                            </small>
                        </CListGroupItem>
                    </span>
                </CListGroup>
            </CTab>
        </CTabs>
    </CSidebar>
</template>

<script>
export default {
    name: 'TheAside',
    data() {
        return {
            notifications: [],
            aside_notification_counter: 0,
            notification_single_click: false,
        }
    },
    computed: {
        aside_show: function () {
            return this.$store.getters.getAsideShowValue
        }
    },
    methods: {
        clickNotification(reference_id, category, reference_number, index) {
            this.notification_single_click = true;
            this.$store.commit('toggle', 'asideShow')
            let data = {
                reference_id: reference_id,
                reference_number: reference_number,
            }
            if(category == 'Updates') {
                this.aside_notification_counter--;
                this.$emit('clickNotification')
                this.notifications.splice(index, 1)
                axios.post('/notifications/updates', data, {validateStatus: () => true})
                // .then(response => {
                //     if(response.status == 200) {
                //         this.aside_notification_counter - 1;
                //         // this.$emit('clickNotification')
                //     }
                // })
            }
        }
    },
    watch: {
        aside_show: function (value) {
            if(!value && this.aside_notification_counter > 0 && !this.notification_single_click) {
                axios.post('/notifications/updates', {validateStatus: () => true})
                this.getUserNotification();
            }
            this.notification_single_click = false
        }
    }
}
</script>
