<template>
    <CDropdown
        inNav
        class="c-header-nav-items"
        placement="bottom-end"
        add-menu-classes="pt-0"
    >
        <template #toggler>
            <CHeaderNavLink>
                <div class="c-avatar">
                    <CIcon name="cil-user"/> 
                </div>
                {{ $store.getters['getUserDetails'] ? $store.getters['getUserDetails'].first_name : ''}}
            </CHeaderNavLink>
        </template>
        <CDropdownHeader
            tag="div"
            class="text-center"
            color="light"
        >
            <strong>Settings</strong>
        </CDropdownHeader>
        <CDropdownItem  @click="profile()">
            <CIcon name="cil-user"/> Profile
        </CDropdownItem>
        <CDropdownItem @click="setting()">
            <CIcon name="cil-settings" /> Settings
        </CDropdownItem>
        <CDropdownDivider/>
        <CDropdownItem @click="logoutUser($store.getters['getOrganizationName'])">
        <CIcon name="cil-lock-locked" /> Logout
        </CDropdownItem>
    </CDropdown>
</template>

<script>
    export default {
        name: "TheHeaderDropdownAccnt",
        data() {
            return {
              itemsCount: 42,
            };
        },
        methods: {
            profile: function () {
                const path = `/${this.$store.getters.getOrganizationName}/me`
                if (this.$route.path !== path) this.$router.push(path)
            },
            setting: function () {
                const path = `/${this.$store.getters.getOrganizationName}/settings`
                if (this.$route.path !== path) this.$router.push(path)
            }
        },
    };
</script>


<style scoped>
    .c-icon {
        margin-right: 0.3rem;
    }
</style>