<template>
  <CFooter :fixed="false">
    <div>
      <a href="#" target="_blank">Avante</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}}  </span>
    </div>
    <div class="ml-auto">
      <span class="mr-1" target="_blank">Crafted with ♡️ by</span>
      <a href="https://amti.com.ph/">AMTI MIS-TEAM</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
