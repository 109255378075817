<template>
    <CSidebar :minimize="minimize" unfoldable :show="show" @update:show="(value) => $store.commit('set', ['sidebarShow', value])">
        <CSidebarBrand :to="$store.getters.getUserDetails ? '/'+$store.getters.getOrganizationName+'/dashboard' : '/'">
            <div :style="{ 'background-image':'url('+ icon +')' }" class="c-sidebar-brand-minimized logo-mini"></div>

            <div :style="{ 'background-image':'url('+ logo +')' }" class="c-sidebar-brand-full logo-full"></div>
            <!-- <CIcon class="c-sidebar-brand-full" name="logo" size="custom-size" :height="35" viewBox="0 0 556 134" /> -->
            <!-- <CIcon class="c-sidebar-brand-minimized" name="logo" size="custom-size" :height="35" viewBox="0 0 110 134" /> -->
        </CSidebarBrand>
        <CRenderFunction flat :contentToRender="this.$options.navs.methods.navigationItems()" />
        <CSidebarMinimizer class="c-d-md-down-none" @click.native="$store.commit('toggle', 'sidebarMinimize')" />
    </CSidebar>
</template>
<script>
import navs from './_nav'
export default {
    name: 'TheSidebar',
    navs,
    computed: {
        show () {
            return this.$store.state.sidebarShow 
        },
        minimize () {
            return this.$store.state.sidebarMinimize 
        },
        icon (){
            if(this.$store.getters.getOrganizationKey) {
                return this.$backend_url+"/organization/images/icon/"+this.$store.getters.getOrganizationKey
            }
        },
        logo (){
            if(this.$store.getters.getOrganizationKey) {
                return this.$backend_url+"/organization/images/logo/"+this.$store.getters.getOrganizationKey
            }
        }

    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.name || 'Some Default Title';
            }
        },
    }

}
</script>