<template>
	<div class="c-app" :class="{ 'c-dark-theme': $store.getters.getDarkMode}">
		<TheSidebar/>
		<CWrapper>
		<TheHeader ref="_theHeader"/>
		<TheAside ref="_theAside" @clickNotification="$refs._theHeader.header_notification_counter--"/>
		<div class="c-body">
			<main class="c-main">
			<CContainer fluid>
				<transition name="fade">
				<router-view></router-view>
				</transition>
			</CContainer>
			</main>
			<TheFooter/>
		</div>
		</CWrapper>
	</div>
</template>

<script>
import TheAside from './TheAside'
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
// import { mapGetters } from 'vuex'


export default {
	name: 'TheContainer',
	components: {
		TheAside,
		TheSidebar,
		TheHeader,
		TheFooter
	},
	computed: {
		my_notifications: function() {
			return this.$store.getters.getUserNotifications
		},
	},
	created() {
		this.getUserNotification();
		this.applyThemes();
	},
	methods : {
		applyThemes : function () {
			const theme = this.$store.getters.getDarkMode ? 'dark' : 'light';
			document.body.classList.remove('theme-dark', 'theme-dark-popover');
			if(theme == 'dark') {
				document.body.classList.add(`theme-dark-popover`);
				document.body.classList.add(`theme-dark`);
			}
		}
	},
	mounted() {
		let data = this.my_notifications
		if(data) {
			let notification_counter = 0;
			let notifications = []
			for (let i = 0; i < data.length; i++) {
				let row_header = {
					type: 'header',
					title: data[i].module_name
				}
				notifications.push(row_header)
				for (let j = 0; j < data[i].notifications.length; j++) {
					notification_counter++;
					let row_child = {
						type: 'child',
						title: data[i].notifications[j].title,
						text: data[i].notifications[j].reference_number,
						date: this.changeDateFormat(data[i].notifications[j].created_at),
						reference_id: data[i].notifications[j].reference_id,
						category: data[i].notifications[j].type,
						status: this.getDocumentStatus(data[i].notifications[j].header_status)
					}
					notifications.push(row_child)   
				}                     
			}
			this.$refs._theHeader.header_notification_counter = notification_counter;
			this.$refs._theAside.aside_notification_counter = notification_counter;
			this.$refs._theAside.notifications = notifications;
		}

		// ==========
	},
	watch: {
		my_notifications: function(data) {
			if(data) {
				let notification_counter = 0;
				let notifications = []
				for (let i = 0; i < data.length; i++) {
					let row_header = {
						type: 'header',
						title: data[i].module_name
					}
					notifications.push(row_header)
					// for (let j = 0; j < data[i].notifications.length; j++) {
					for (let j = data[i].notifications.length - 1; j >= 0; j--) {
						notification_counter++;
						let row_child = {
							type: 'child',
							title: data[i].notifications[j].title,
							text: data[i].notifications[j].reference_number,
							date: this.changeDateFormat(data[i].notifications[j].created_at),
							reference_id: data[i].notifications[j].reference_id,
							category: data[i].notifications[j].type,
							status: this.getDocumentStatus(data[i].notifications[j].header_status)
						}
						notifications.push(row_child)   
					}                     
				}
				this.$refs._theHeader.header_notification_counter = notification_counter;
				this.$refs._theAside.aside_notification_counter = notification_counter;
				this.$refs._theAside.notifications = notifications;
			}
		},
	}
}
</script>

<style scoped>
	.fade-enter-active, .fade-leave-active {
		transition: opacity 0.3s;
	}
	.fade-enter, .fade-leave-to {
		opacity: 0;
	}
</style>
