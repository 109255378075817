import store from '@/store'
export default {
    methods: {
        navigationItems: function () {
            let app_navigation = store.getters.getAppNavigation
            let organization_name = store.getters.getOrganizationName
            let user_navigation = store.getters.getUserNavigation ?? []
            let user_module = store.getters.getUserModule ?? []
            let sidebar = []
            let parent_nav = {
                    _name: "CSidebarNav",
                    _children: []
            }
            sidebar.push(parent_nav);
            
            if(app_navigation)
            {
                // !! DEFAULT, VISIBLE TO ALL USERS !! //
                let dashboard = {
                    _name: "CSidebarNavItem", name: "Dashboard", to: `/${organization_name}/dashboard`, icon: "cil-speedometer"
                }
                let module_title = {
                    _name: "CSidebarNavTitle", _children: ["Groups of Module"]
                }
                sidebar[0]._children.push(
                    dashboard,
                    module_title
                )
                // !! END DEFAULT !! //

                // DYNAMIC, FROM DATABASE
                for (let i = 0; i < app_navigation.length; i++) {
                    let children = [];
                    // !! THIS IS FOR GROUPS !! //
                    let group = {
                        _name:     "CSidebarNavDropdown", 
                        name:      app_navigation[i].name, 
                        route:     `/${organization_name}${app_navigation[i].path}`, 
                        icon:      app_navigation[i].icon,
                    }
                    
                    if(app_navigation[i].modules) {
                        for (let j = 0; j < app_navigation[i].modules.length; j++) {
                            // !! THIS IS FOR MODULE !! //
                            let module =  {
                                name: app_navigation[i].modules[j].name, 
                                to: `/${organization_name}${app_navigation[i].path}${app_navigation[i].modules[j].url}`,
                                icon: app_navigation[i].modules[j].icon 
                            }
                            
                            // !! COMPARING USER MODULE TO USER PERMISSION !! //
                            user_module.filter(function (m) { 
                                if(m.name === module.name) {
                                    children.push(module)
                                } 
                            });
                        }
                    }
                    group.items = children;

                    // !! COMPARING USER NAVIGATION TO USER PERMISSION !! //
                    user_navigation.filter(function (g) { 
                        if(g.name === group.name) {
                            sidebar[0]._children.push(group)
                        } 
                    });
                }
            }
            return sidebar;
        }
    },
    
}